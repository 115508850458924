@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,700&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.sidenavheadings {
  font-size: 50px !important;
  font-family: Poppins !important;
  color: black;
}

.AddressCard {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  width: 137px !important;
  background-color: #ededed;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 10px 10px 0;
}

.CardInnerIcons {
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-top: 5px;
}

.CardBody {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.cardstyle {
  height: 116px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 9px;
}

.addressImage {
  object-fit: cover;
  max-width: 103px;
  height: 69px;
}

.viewIcon {
  cursor: pointer;
  width: 19px !important;
}

.viewIconn {
  cursor: pointer !important;
  width: 19px !important;
  margin-right: 10px;
}

.RuleNos {
  font-weight: 700;
  font-size: 14px;
  font-family: Inter;
  line-height: 26px;
}

.navLinkText {
  text-decoration: none;
  color: black;
}

.navLinkText div {
  margin: 10px 0;
  border-radius: 30px;
  padding: 0px 10px;
}

.logOutText {
  text-decoration: none;
  color: black;
}

.logOutText div {
  margin: 10px 0;
  /* border-radius: 30px; */
  padding: 0px 10px;
  border-top: 1px solid black;
}

.listItemText {
  margin-left: 20px;
  border-top: none !important;
}

.listItemText span {
  font-size: 14px;
  font-weight: 600;
}

.navLinkText.active div {
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 0px 10px;
}

.navLinkText div:hover {
  background-color: black;
  color: white;
  border-radius: 30px;
}

.logOutText div:hover {
  background-color: black;
  color: white;
  border-radius: 30px;
}

.navLinkText div .sideNavImg {
  padding: 6px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logOutText div .sideNavImg {
  padding: 6px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none !important;
}
.logoCardBorder{
  border-bottom: 1px solid black;
}

.sideNavImg img {
  width: 20px;
  height: 20px;
}

.navLinkText div:hover .sideNavImg {
  background: white;
}

.logOutText div:hover .sideNavImg {
  background: white;
}

.navLinkText.active div .sideNavImg {
  background: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLinkBox {
  padding: 10px 0px 10px 10px;
  display: flex;
  flex-direction: column !important;
  height: 100vh;
  overflow-y: scroll;
}

.navLinkContainer {
  padding: 10px 0px 10px 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
}

.navScroll {
  overflow-y: scroll;
}

.PdfCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.textArea {
  border-radius: 10px;
  border: 1px solid #cccccc;
  padding: 8.5px 14px;
  color: black;
  height: 120px !important;
  overflow-y: scroll !important;
}

.contactTextArea {
  border-radius: 10px;
  border: 1px solid #cccccc;
  padding: 8.5px 14px;
  color: black;
  height: 180px !important;
  overflow-y: scroll !important;
}

.textField {
  font-size: 14px !important;
}

.textArea::placeholder {
  font-size: 15px;
  color: #acacac;
}

.react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 24px;
  font-size: 14px;
  height: 38px;
  text-indent: 16px;
  cursor: pointer;
}

.positionParentDiv {
  position: relative;
}

.calendarIcon {
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
}

.calendarIconTwo {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.react-datepicker-popper{
  z-index: 9 !important;
}
.FilterStyle {
  width: 170px;
  margin-right: 10px;
}

.menuModal div {
  width: 400px !important;
}

.notificationHeading {
  font-weight: 700 !important;
}

.itemBorder {
  border-bottom: 1px solid #e9ecef !important;
  cursor: auto !important;
}

.iconBorder {
  border-right: 1px solid #e9ecef;
  margin-right: 15px !important;
  height: 30px !important;
}

.badgeIcon span {
  background-color: #e11f26 !important;
}

.notificationemp {
  font-size: 13px !important;
  color: black !important;
}

.notificationAdd {
  font-size: 12px !important;
  color: #979899 !important;
}

.notificationView {
  background-color: #000000 !important;
  font-size: 12px !important;
  color: white !important;
  height: 24px !important;
  font-weight: 600 !important;
}

.serial-number {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.serial-number-text {
  font-weight: bold;
  color: black;
  font-size: 24px;
  margin-right: 10px;
}

.unBlockIcon {
  color: grey;
}

.MuiPaginationItem-root {
  background-color: #eeeeee !important;
  color: black !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: black !important;
  color: white !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.tabTable {
  height: 82%;
  padding: 0 !important;
}

.tabPanel {
  height: 72%;
}

.cardImage {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  padding: 10px;
}

.blockTextColor,
.unblockTextColor {
  color: #ffb525;
}

.deleteTextColor {
  color: #ff4e4e;
}

.modifyTextColor {
  color: #19b200;
}

.container1_1_text_sep {
  color: #ffffff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.checkStyles{
  color: #000 !important;
  padding: 0px !important;
  padding-left: 10px !important;
}
.checkStyless{
  color: #000 !important;
}


/* Target the outline of the select dropdown */
/* .MuiOutlinedInput-notchedOutline {
  border: none !important; 
} */


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.IconStyle svg path {
  fill: black !important;
}
.css-1wtgei3-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
  color: black !important;
}

.BadgeStyles span{
  min-width: 15px !important;
  height: 15px !important;
top: 25% !important;
right: 20% !important;
}
